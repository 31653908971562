/* Taken from getting started guide at: https://leafletjs.com/examples/quick-start/ */
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap";

/* These CSS variables are global, so they are available in all CSS modules */
:root {
  --color: 230;
  --color-brand--1: hsl(var(--color), 69%, 61%);
  /* --color-brand--1: #476eb1; */
  /* --color-brand--1: green; */
  --color-brand--2: #285880;
  --color-dark--0: #242a2e;
  --color-dark--1: #2d3439;
  --color-dark--2: #42484d;
  --color-light--1: #f2f2f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: "Manrope", sans-serif;
  color: var(--color-light--2);
  font-weight: 400;
  line-height: 1.6;
  scroll-behavior: smooth;
}

.cta:link,
.cta:visited {
  display: inline-block;
  background-color: var(--color-brand--2);
  color: var(--color-brand--1);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 3rem;
  border-radius: 5px;
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--color-brand--2);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-brand--1);
  border-radius: 0.5rem;
}
