footer {
  background-color: var(--color-dark--0);
  padding: 70px 50px 50px;
}
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .about {
  max-width: 33.3%;
}

.footer h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--color-light--1);
}

.quickLinks {
  max-width: 16.6%;
}

.quickLinks ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: left;
  padding-left: 0;
}

.quickLinks a:link,
.quickLinks a:visited {
  text-decoration: none;
  color: var(--color-light--2);
}

.quickLinks a:hover {
  color: var(--color-brand--1);
}

.footer .contact {
  max-width: 33.3%;
}
footer {
  font-size: 1.5rem;
  text-align: justify;
  color: var(--color-light--1);
}

center a {
  text-decoration: none;
  color: var(--color-brand--1);
}
center a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .footer .contact {
    max-width: 50%;
  }
  .footer .about {
    max-width: 50%;
  }
  .quickLinks {
    max-width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  footer {
    padding: 70px 20px 50px;
  }
  .footer {
    gap: 2rem;
  }
  .footer .contact {
    max-width: 100%;
  }
  .footer .about {
    max-width: 100%;
  }
  .quickLinks {
    max-width: 100%;
  }
}
