.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 50px 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: top 0.3s ease background-color 0.3 ease;
  animation: slideDown 0.5s ease;
}
.nav.fixed {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #000;
}
@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

.nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav a:link,
.nav a:visited {
  text-decoration: none;
  color: #000;
  /* color: #fff; */
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
}
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav a:global(.active) {
  color: var(--color-brand--1);
}
.nav a:hover {
  color: var(--color-brand--1);
}

.dropDown {
  float: left;
  overflow: hidden;
}
.dropDown .dropBtn {
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: inherit;
}
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdownContent a {
  padding: 10px 15px;
}

.dropDown:hover {
  color: var(--color-brand--1);
}
.dropDown:hover .dropdownContent {
  display: flex;
  flex-direction: column;
}
.close,
.menuToggle {
  display: none;
}
@media (max-width: 880px) {
  .nav {
    padding: 10px 20px;
  }

  .menuToggle {
    display: block;
    font-size: 3rem;
    background: transparent;
    border: none;
  }
  .nav .menu {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: -300px;
    height: 100vh;
    width: 300px;
    background-color: var(--color-light--1);
    box-shadow: 10px 40px 60px rgba(256, 256, 256, 0.5);
    padding: 80px 0 0 30px;
    transition: 0.3s;
    z-index: 999;
  }
  .nav ul {
    gap: 1.5rem;
  }

  .nav .close {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 30px;
    color: #222;
    font-size: 24px;
    display: initial;
    background-color: transparent;
    border: none;
  }

  .menu.open {
    right: 0px;
  }
  .dropDown {
    float: none;
  }

  .dropBtn {
    display: block;
    width: 100%;
    text-align: left;
  }
  .dropdownContent {
    position: relative;
    box-shadow: none;
    background-color: inherit;
  }
  .dropdownContent a {
    padding: 0;
    margin-top: 15px;
  }
}
